import React from 'react';
//Bootstrap CSS (this is first)
import 'bootstrap/dist/css/bootstrap.min.css';
//Styling CSS (this is 2nd so it overrides Bootstrap CSS and we don't need !important)
import '@/styles/main.scss';
//FontAwesome
import { library, config } from '@fortawesome/fontawesome-svg-core';
import {
	faMapMarkerAlt,
	faChevronDown,
	faPhone,
	faClock,
	faEnvelope,
	faExternalLinkAlt,
	faCalendarCheck,
	faShieldVirus,
	faCommentMedical,
	faPlaneDeparture,
	faAddressCard,
	faAddressBook
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';
import Router from 'next/router';
//Nprogress module
import NProgress from 'nprogress';
//Styles of nprogress
import '@/styles/shared/nprogress.scss';
//Binding events on progress.
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
//Font Awesome Settings
config.autoAddCss = false;
library.add(
	faMapMarkerAlt,
	faEnvelope,
	faChevronDown,
	faPhone,
	faClock,
	faCalendarCheck,
	faExternalLinkAlt,
	faFacebookSquare,
	faInstagram,
	faShieldVirus,
	faCommentMedical,
	faPlaneDeparture,
	faAddressCard,
	faAddressBook
);

const App = ({ Component, pageProps }) => {
	return <Component {...pageProps} />;
};

export default App;
